import {plugin_registry, PluginBase} from "nk-plugin-registry";

import "../scss/soundcloud.scss";

const soundcloud_instances = new Map();

@plugin_registry.register('Soundcloud')
class Soundcloud extends PluginBase {
    constructor($node) {
        super($node);
        this._$node = $node;
    }

    connect($node) {
        super.connect($node);
        this._$node = $node;
        soundcloud_instances.set(this._$node, this);
    }

    loaded($node) {
        super.loaded($node);
        this._$video_overlay = this._$node.querySelector('.consent-overlay');
        this._$video_overlay_activate_button = this._$node.querySelector('[data-js-select="activate-button"]');
        this._$video = this._$node.querySelector('[data-js-select="soundcloud-embed"]');

        this._$video_overlay_activate_button.addEventListener('click', this._activate_all)
    }

    disconnect($node) {
        super.disconnect($node);
        this._$node = null;
        this._$video_overlay_activate_button = null;
        this._$video_overlay = null;
        soundcloud_instances.delete(this._$node);
    }

    activate = () => {
        this._$video_overlay.classList.add('hide');
        this._$video.classList.remove('hide');
        const video_url = this._$video.dataset['src'];
        this._$video.setAttribute('src', video_url);
        this._$video_overlay_activate_button.removeEventListener('click', this._activate);
        this._$video_overlay_activate_button = null;
    }

    _activate_all = (event) => {
        this.activate();

        // activate all other video players
        for (const [$node, instance] of soundcloud_instances) {
            if (this._$node !== $node) {
                instance.activate()
            }
        }
    }
}
