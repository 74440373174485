import "scss/index.scss";


import { plugin_registry } from './nk-plugin-registry';
// import your plugins here.
import "header/js/header.js";
import "footer/js/footer.js";
import "youtube_video/js/youtube_video.js";
import "soundcloud/js/soundcloud.js";
import "image/js/image.js";
import "../../okj/js/extendable.js";
import "../../okj/js/calendar.js";
import "self_hosted_video/js/self_hosted_video.js";
import "sponsors/js/sponsors";
import "stage/js/stage.js";
import "stream_player/js/stream_player.js";
import "../../okj/js/audioload.js";


plugin_registry.init();

