import {plugin_registry, PluginBase} from "nk-plugin-registry";
import '../scss/calendar.scss';
import '../scss/mobile-calendar.scss'
import tpl_calendar from './templates/calendar.nunjucks';


@plugin_registry.register('EventCalendar')
class EventCalendar extends PluginBase {
  constructor($node) {
    super($node);
    this.$node = $node;
    this.$calendar_grid = $node.querySelector('[data-js-select="calendar-grid"]');
    this.$calendar_details = $node.querySelector('[data-js-select="calendar-details"]');
    this.$next_button = $node.querySelector('[data-js-select="calendar-next"]');
    this.$prev_button = $node.querySelector('[data-js-select="calendar-prev"]');
    this.$calendar_month_display = $node.querySelector('[data-js-select="calendar-month-name"]');
    this._locale = null;
    this._month = null;
    this._day = null;
    this._year = null;
    this._today = new Date(Date.parse($node.querySelector('[data-js-select="today"]').innerText));
    this._weekdays_cache = null;
    this._data_cache = {};
    this._urlParams = new URLSearchParams(window.location.search);
  }

  get month() {

    if (!this._month) {
      if (this._urlParams.get("date") && Number.isSafeInteger(parseInt(this._urlParams.get("date").split(".")[1]))) {
        this._month = parseInt(this._urlParams.get("date").split(".")[1])
      } else {
        this._month = this._today.getMonth() + 1
      }
    }
    return this._month
  }

  set month(new_month) {
    this._month = new_month;
  }

  get month_name() {
    return new Date(this.year, this.month - 1, this.day)
      .toLocaleDateString(this.locale, {month: 'long'})
  }

  get day() {
    if (!this._day) {
      if (this._urlParams.get("date") && Number.isSafeInteger(parseInt(this._urlParams.get("date").split(".")[0]))) {
        this._day = parseInt(this._urlParams.get("date").split(".")[0])
      } else {
        this._day = this._today.getDate()
      }
    }
    return this._day
  }

  set day(new_day) {
    this._day = new_day
  }

  get year() {
    if (!this._year) {
      if (this._urlParams.get("date") && Number.isSafeInteger(parseInt(this._urlParams.get("date").split(".")[2]))) {
        this._year = parseInt(this._urlParams.get("date").split(".")[2])
      } else {
        this._year = this._today.getFullYear()
      }
    }
    return this._year
  }

  set year(new_year) {
    this._year = new_year
  }

  get _calendar_offset() {
    const month_index = this.month - 1;
    const year = this.year;
    const first_of_month = new Date(year, month_index, 1);
    const offset = first_of_month.getDay() - 1; // minus one since our week starts on monday not sunday
    if (offset < 0) {
      return 6;
    }
    return offset;
  }

  get days_in_month() {
    return new Date(this.year, this.month, 0).getDate();
  }

  connect() {
    this.$next_button.addEventListener('click', this.next_month);
    this.$prev_button.addEventListener('click', this.prev_month);
    this.$calendar_grid.addEventListener('click', this._set_date_from_link);
    this._update();
  }

  _get_date_from_url = () => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("date")) {
      this._day = parseInt(urlParams.get("date").split(".")[0])
      const dateStr = urlParams.get("date")
      const intDay = parseInt(dateStr.split(".")[0])
      const intMonth = parseInt(dateStr.split(".")[1])
      const intYear = parseInt(dateStr.split(".")[2])

      if (!Number.isSafeInteger(intYear) || !Number.isSafeInteger(intMonth) || !Number.isSafeInteger(intYear)) {
        return {
          day: this._today.getDate(),
          month: this._today.getMonth() + 1,
          year: this._today.getFullYear()
        }
      }

      return {
        day: intDay,
        month: intMonth,
        year: intYear
      }
    } else {
      return {
        day: this._today.getDate(),
        month: this._today.getMonth() + 1,
        year: this._today.getFullYear()
      }
    }

  }

  _set_date_from_link = (event) => {
    //event.preventDefault();
    const $target = event.target;
    const day = $target.dataset['day'];
    if (day) {
      this.day = parseInt(day);
      this._update();
    }
    //event.submit()
  }

  next_month = (event) => {
    event.preventDefault();
    const month_candidate = this.month + 1;
    if (month_candidate > 12) {
      this.month = month_candidate % 12;
      this.year = this.year + 1;
    } else {
      this.month = month_candidate;
    }
    this.day = 1;
    this._update_data();
  }

  prev_month = (event) => {
    event.preventDefault();
    const month_candidate = this.month - 1;
    if (month_candidate < 1) {
      this.month = 12;
      this.year = this.year - 1;
    } else {
      this.month = month_candidate;
    }
    this.day = 1;
    this._update_data();
  }

  _update = () => {
    this._render_calendar_grid();
    this._render_calendar_header();
  }

  get locale() {
    if (!this._locale) {
      const $html = document.querySelector('html');
      const locale = $html.getAttribute('lang');
      this._locale = locale;
    }
    return this._locale;
  }

  get _current_data_cache_key() {
    return `${this.year}-${String(this.month).padStart(2, '0')}`;
  }

  _update_data = () => {
    const data_url = new URL(
      this._data_base_url,
      `${window.location.protocol}//${window.location.host}/`
    );
    const cache_key = this._current_data_cache_key;

    data_url.search = new URLSearchParams(
      [
        ['year', this.year],
        ['month', this.month],
      ]
    ).toString();

    if (!this._data_cache.hasOwnProperty(cache_key)) {
      fetch(data_url)
        .then(response => response.json())
        .then(data => {
          this._data_cache[cache_key] = data;
        })
        .catch((reason => {}))
        .finally(this._update);
    } else {
      this._update();
    }
  }

  _get_weekdays_localized = () => {
    if (!this._weekdays_cache) {
      const a_sunday = new Date(2021, 2, 7);
      this._weekdays_cache = new Array(7).fill(undefined).map(
        (value, index) => {
          a_sunday.setDate(a_sunday.getDate() + 1)
          return a_sunday.toLocaleDateString(this.locale, {weekday: 'short'});
        },
      )
    }
    return this._weekdays_cache
  }


  _render_calendar_grid = () => {
    const dateFromUrl = this._get_date_from_url()

    const days = new Array(this.days_in_month)
    const context = {
      weekdays: this._get_weekdays_localized(),
      month_name: this.month,
      days: [
        ...new Array(this._calendar_offset), // padding
        ...days.fill(undefined).map((value, index) => ({
            day: index + 1,
            month: this.month,
            year: this.year,
            current: index + 1 === dateFromUrl.day && this.month === dateFromUrl.month && this.year === dateFromUrl.year,
          }),
        ),
      ],
    }
    this.$calendar_grid.innerHTML = tpl_calendar.render(
      context,
    );
  }

  _render_calendar_header = () => {
    this.$calendar_month_display.innerText = `${this.month_name} ${this.year}`;
  }
}
