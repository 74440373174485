import Swiper, {Navigation, Pagination, Autoplay} from "swiper";
import 'swiper/css/bundle';

import "../scss/stage.scss";
import {plugin_registry, PluginBase} from "nk-plugin-registry";

@plugin_registry.register("Stage")
class Stage extends PluginBase {
  constructor($node) {
    super($node);
    this._$node = $node;
  }

  loaded($node) {
    super.loaded($node);
    const delay = this._$node.querySelector('[data-delay]').dataset.delay;
    this._swiper = new Swiper(
      this._$node.querySelector('[data-js-select="swiper"]'),

      {
        modules: [Navigation, Pagination, Autoplay],
        autoHeight: true,
        navigation: {
          nextEl: this._$node.querySelector('[data-js-select="swiper-next"]'),
          prevEl: this._$node.querySelector('[data-js-select="swiper-prev"]'),
        },
        pagination: {
          el: this._$node.querySelector('[data-js-select="swiper-pagination"]'),
        },
        speed: 500,
        loop: true,
        autoplay: delay ? {delay, disableOnInteraction: true, pauseOnMouseEnter: true} : false,
        on: {
          transitionStart: () => {
            this._$node.querySelectorAll('video').forEach((elem) => {
              elem.pause();
            })
          },
        },
      },
    );

  }
}
